import React, {useEffect, useRef, useState} from "react";
import {useMountEffect} from "../../hooks/mounting-hooks";
import {SEC} from "../../../../util/time";
import MyCut from "./MyCut";
import TotalPot from "./TotalPot";
import Population from "./Population";
import './counter.scss'
import CheeseSlicers from "./CheeseSlicers";

const Counter = ({totalValue, momentum, population, oilPriceNOK}) => {

    const [tempValue, setTempValue] = useState(totalValue)

    const tickSize = useRef(0)
    const currentValue = useRef(0)

    const momentumPerCapita = momentum / population

    useEffect(()=>{
        currentValue > 0 && (tickSize.current = (totalValue - currentValue) / (60 * 8))
    },[totalValue])

    useMountEffect(()=>{
        currentValue.current = totalValue - momentum
        tickSize.current = momentum / (60 * 8)
    })


    const refreshValue = () => {
        currentValue.current += tickSize.current
        setTempValue(currentValue.current)
    }

    useEffect(() => {
        const timer = setInterval(() => {
            refreshValue()
        }, SEC / 8);
        return () => clearTimeout(timer);
    }, [])

    const tempValuePerCapita = tempValue/population

    return (
        <div className='counter'>
            <Population value={population} />
            <TotalPot value={tempValue}/>
            <MyCut value={tempValuePerCapita} momentum={momentumPerCapita} />
            <CheeseSlicers value={tempValuePerCapita} oilPriceNOK={oilPriceNOK}/>
        </div>
    )
}

export default Counter