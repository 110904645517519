import React from "react";
import './background.scss';
import kransekake from '../assets/gfx/kransekake.png'
import poles from '../assets/gfx/poles.png'

const Background = () => {
    return (
        <div className='background'>
            <img
                src={kransekake}
                alt={'kransekake'}
                className='kransekake'
            />
            <img
                src={poles}
                alt={'poles'}
                className='poles'
            />
        </div>
    )
}

export default Background