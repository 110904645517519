import React, {useState} from "react";
import jaViElsker from '../../assets/audio/ja-vi-elsker.mp3';
import {useMountEffect} from "../../hooks/mounting-hooks";
import './jukebox.scss';
import PlayIcon from "mdi-react/PlayIcon";
import PauseIcon from "mdi-react/PauseIcon";

const Jukebox = () => {

    const [isPlaying, setIsPlaying] = useState(false)

    const toggleAudio = () => {
        const audioEl = document.getElementsByClassName("audio-element")[0]
        if(isPlaying){
            audioEl.pause()
            setIsPlaying(false)
        } else {
            audioEl.play()
            setIsPlaying(true)
        }
    }

    useMountEffect(()=>{
        const audioEl = document.getElementsByClassName("audio-element")[0]
        audioEl.loop = true
    })



    return (
        <div className='jukebox'>
            <button onClick={toggleAudio}>
                {isPlaying ? <PauseIcon /> : <PlayIcon />}
            </button>
            <audio className="audio-element">
                <source src={jaViElsker} />
            </audio>
        </div>
    )
}

export default Jukebox