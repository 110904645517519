import React from "react";
import {numberFormat} from "../../util/formatting/numbers";
import './total-pot.scss'

const TotalPot = ({value}) => {
    return (
        <div className='total-pot'>
            {numberFormat(value)}
            <span className='desc'>
                kroner i kassen
            </span>
        </div>
    )
}

export default TotalPot