import React from "react";
import {numberFormat} from "../../util/formatting/numbers";
import './my-cut.scss'

const MyCut = ({value}) => {
    return (
        <div className='my-cut'>
            {numberFormat(value, 0)}
            <span className='desc'>
                kroner per hode
            </span>
        </div>
    )
}

export default MyCut