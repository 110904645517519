import React from "react";
import {numberFormat} from "../../util/formatting/numbers";
import './population.scss'

const Population = ({value}) => {
    return (
        <div className='population'>
            {numberFormat(value)}
            <span className='desc'>
                folk i norske hjem
            </span>
        </div>
    )
}

export default Population