export function numberFormat(number, decimals = 0, usePostfix = false) {
  if (usePostfix) {
    let postfix = ''
    if (Math.abs(number) > 100000) {
      postfix = 'M'
      decimals = 2
      number = (Math.round(number / 10000) / 100)
    } else if (Math.abs(number) > 1000) {
      postfix = 'K'
      decimals = 2
      number = Math.round(number / 1000)
    } else if(number !== 0) {
      decimals = 0
      number = Math.round(number)
    } else {
      return '—'
    }
    return number_format(number, decimals, ',', "\u00a0") + postfix
  }
  return number_format(number, decimals, ',', "\u00a0")
}

export function number_format(number, decimals, decPoint, thousandsSep) {
  number = (number + '')
    .replace(/[^0-9+\-Ee.]/g, '')
  var n = !isFinite(+number) ? 0 : +number
  var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
  var sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
  var dec = (typeof decPoint === 'undefined') ? '.' : decPoint
  var s = ''
  var toFixedFix = function (n, prec) {
    var k = Math.pow(10, prec)
    return '' + (Math.round(n * k) / k)
      .toFixed(prec)
  }
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n))
    .split('.')
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
  }
  if ((s[1] || '')
    .length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1)
      .join('0')
  }
  return s.join(dec)
}

export const intToLetter = int => String.fromCharCode(97 + int).toUpperCase()