import {OK, UNAUTHORIZED} from "http-status-codes";

export const post = (path, data) => requestWithData('POST', path, data)
export const patch = (path, data) => requestWithData('PATCH', path, data)
export const put = (path, data) => requestWithData('PUT', path, data)
export const get = (path, data) => request('GET', path, data)

const requestWithData = async (method, path, data = {}) => request(method, path, data)

const request = async (method, path, data) => {
    let response = await fetch(process.env.REACT_APP_API_HOST + path, {
        method: method,
        headers: {
            ...(data && { 'Content-Type': 'application/json' }),
        },
        ...(data && { body: JSON.stringify(data) }),
    });

    return handleResponse(response)

}

const handleResponse = async response => {
    const json = await response.json()
    if(response.status === UNAUTHORIZED){
        return Promise.reject({ ...json, status: response.status })
    }
    return response.status !== OK ? Promise.reject({ ...json, status: response.status }) : json
}