import React, {useEffect, useState} from 'react';
import './app.scss';
import Counter from "./components/counter/Counter";
import {API_ROUTE_STATUS} from "../../constants/api-routes";
import {get} from "./api/fetch";
import {MIN} from "../../util/time";
import Background from "./components/Background";
import Jukebox from "./components/music-player/Jukebox";

function App() {

    const [population, setPopulation] = useState()
    const [momentum, setMomentum] = useState()
    const [totalValue, setTotalValue] = useState()
    const [oilPriceNOK, setOilPriceNOK] = useState()

    const fetchData = async () => {
        const {totalValue, momentum, population, oilPriceNOK} = await get(API_ROUTE_STATUS)
        setPopulation(population)
        setMomentum(momentum)
        setTotalValue(totalValue)
        setOilPriceNOK(oilPriceNOK)
    }

    useEffect(() => {
        fetchData().then()
        const timer = setInterval(fetchData, MIN);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="App">
            <Background />
            <header className="App-header">
                <span>min</span>
                <span>del</span>
                <span>av</span>
                <span>kaka</span>
                <span>.no</span>
            </header>
            <main>
                <h1>
                    Hvor mye har jeg i oljefondet?
                </h1>
                {totalValue &&
                    <Counter
                        totalValue={totalValue}
                        momentum={momentum}
                        population={population}
                        oilPriceNOK={oilPriceNOK}
                    />
                }
                <Jukebox />
            </main>
        </div>
    );
}

export default App;
